import React, { useState } from 'react';
import ReportWrraper from '@/components/report';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EFORMAT_API, ReportFormat } from '@/entity';
import { OnFinishData } from '@/components/report/type';
import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import { INTERVALS } from '@/components/selectReportingMethod/type';
import PageLoader from '@/components/PageLoader/PageLoader';
import { useCreateReport } from '@/hooks/useCreateReport';
import { GoogleOAuthProvider } from '@react-oauth/google';

const MOCK_CREATE_REPORT_BODY = {
  name: 'Test create 1',
  startDate: '2024-07-04T12:14:58.503Z',
  endDate: '2024-07-04T12:14:58.503Z',
  deliveryFormat: 'SCHEDULED_EMAIL',
  reportParameters: [],
  reportFormat: 'GOOGLE_SHEETS',
  reportTemplateId: 1
};

export const formationSchedule = (data: SelectReportMethodForm) => {
  let offsets: number[] = [];
  let period = INTERVALS.DAYS;

  switch (data.period) {
    //////
    case INTERVALS.DAILY:
      period = INTERVALS.DAYS;
      offsets = [1];
      break;
    case INTERVALS.DAYS:
      period = INTERVALS.DAYS;
      offsets = [1];
      break;
    //////
    case INTERVALS.WEEKLY:
      period = INTERVALS.WEEKS;
      offsets = data.daysOfWeek;
      break;
    case INTERVALS.WEEKS:
      period = INTERVALS.WEEKS;
      offsets = data.daysOfWeek;
      break;
    //////
    case INTERVALS.MONTHLY:
      period = INTERVALS.MONTHS;
      offsets = data.daysOfMonth;
      break;
    case INTERVALS.MONTHS:
      period = INTERVALS.MONTHS;
      offsets = data.daysOfMonth;
      break;
    //////
    default:
      break;
  }

  return {
    frequency: 1,
    period: period,
    offsets: offsets,
    hourOfDay: data.hourOfDay
  };
};

function SelectUserReportTemplatePage(): React.JSX.Element {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { onApply: onApplyCreateReport } = useCreateReport();

  const onApply = async (reqBody: OnFinishData, isGoogleSheet: ReportFormat) => {
    setIsLoading(true);
    const res = await onApplyCreateReport(reqBody, isGoogleSheet);
    setIsLoading(false);
    if (res) {
      navigate('/');
    }
  };

  return (
    <GoogleOAuthProvider
      clientId={'311349960961-nf0mno17e57mimgugfhrq5et23ki93fi.apps.googleusercontent.com'}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: '888px',
            width: '100%',
            marginTop: '64px'
          }}
        >
          <ReportWrraper
            reportStepperStyle={{
              border: 'none',
              boxShadow: 'none'
            }}
            stepsStyle={{
              stepTwo: {
                container: {
                  display: 'flex',
                  justifyContent: 'center'
                },
                block: {
                  width: '474px',
                  padding: '48px 32px',
                  maxHeight: 'auto',
                  minHeight: 'auto'
                }
              }
            }}
            isBackButtonInSecondStep
            onApplyGoogleSheets={form => onApply(form, EFORMAT_API.GOOGLE_SHEETS)}
            onApplyExcel={form => onApply(form, EFORMAT_API.EXCEL)}
          />
        </Box>
      </Box>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#8080805c',
            top: '0',
            left: '0',
            zIndex: '50',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <PageLoader />
        </div>
      )}
    </GoogleOAuthProvider>
  );
}

export default SelectUserReportTemplatePage;
