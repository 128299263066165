import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import PageLoader from '@/components/PageLoader/PageLoader';
import useSession from '@/hooks/useSession';
import { useAuth } from '@/contexts/AuthContext';
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';
import { setInitFlow, setSession } from '@/slices';
import { Auth0Session } from '@/types';
import { getRedirectUrl } from '@/router/Router/AuthCallback/helpers';
import useCheckUserExists from '@/hooks/auth/useCheckUserExists';
import useGetPreparedUserInfo from '@/hooks/auth/useGetPreparedUserInfo';
import { paths } from '@/utils/paths';
import { usePostQuickbookLaunchMutation } from "@/services/api/quickbook/quickbook";

const ACCESS_DENIED = 'access_denied';

const AuthSuccessRedirect = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSession();
  const handleFormApiErrors = useHandleFormApiErrors();
  const { getAuthInfo } = useAuth();
  const [ postQuickbookLaunch] = usePostQuickbookLaunchMutation();

  const checkUserExists = useCheckUserExists();
  const getPreparedUserInfo = useGetPreparedUserInfo();

  useEffect(() => {
    const getAuth = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');
        const realmId = queryParams.get('realmId');

        if (code && state && realmId) {
          try {
            const authResult :any  = await postQuickbookLaunch({code, state, realmId});
            const { auth0User } = await getPreparedUserInfo(authResult?.data?.access_token);

            dispatch(
              setSession({
                ...authResult,
                user: auth0User,
                workspaces: [],
                invitations: []
              })
            );

            return;
          } catch (error) {
            console.error('Error handling auth callback: ', error);
          }
        }

        const authResult: Auth0Session = await getAuthInfo();
        if (!authResult) {
          return;
        }

        const isUserExists = await checkUserExists(authResult);
        if (isUserExists) {
          return;
        }

        if (authResult.accessToken) {
          const { auth0User, userInfo } = await getPreparedUserInfo(authResult.accessToken);

          dispatch(
            setSession({
              ...authResult,
              user: auth0User,
              workspaces: userInfo?.data?.data?.workspaces || [],
              invitations: userInfo?.data?.data?.invitations || [],
              currentWorkspaceId: userInfo?.data?.data?.workspaces?.[0]?.id
            })
          );

          dispatch(
            setInitFlow({ isFirstConnect: true, isRedirectAfterConnectToReportCreate: true })
          );
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (error?.error === ACCESS_DENIED) {
          navigate(paths.verifyEmail);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (error?.errorDescription && error.error !== 'invalid_token') {
          handleFormApiErrors({
            error: {},
            defaultMessage:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              error?.errorDescription ?? 'Something went wrong, please try again.',
            showFieldErrorsAsToast: true
          });
        }
      }
    };

    getAuth();
  }, []);

  useEffect(() => {
    if (user) {
      navigate(getRedirectUrl(), { state: { from: location } });
    }
  }, [user]);

  if (user) {
    return <Navigate to={getRedirectUrl()} state={{ from: location }} />;
  }

  return <PageLoader />;
};

export default AuthSuccessRedirect;
