import { ReportTemplateContent } from '@/services/api/reportTemplates/types';
import { TemplateParameterWithValue } from '@/components/parameterValues/type';
import { TReportBodyReportParameterTab } from '@/services/api/reports/types';

export type TFormat = 'google' | 'excel';

export enum EFORMAT {
  GOOGLE_SHEETS = 'Google Sheets',
  EXCEL = 'Excel'
}

export enum EFORMAT_API {
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  EXCEL = 'EXCEL'
}

export type ReportFormat = 'GOOGLE_SHEETS' | 'EXCEL';

export interface ReportSchedule {
  frequency: number;
  period: 'DAYS' | 'WEEKS' | 'YEARS' | 'MONTHS';
  offsets: number[];
  hourOfDay: number;
  id: number;
}
export interface ReportCompany {
  id: number;
  name: string;
  platform: string;
  createdAt: string;
  createdBy: string;
  userEmail: string;
}

export interface ReportTemplateResponse {
  id: number;
  name: string;
  /// TODO: extend this interface
}

export type TReportDb = {
  id: number;
  companiesDto: unknown[];
  endDate: string;
  startDate: string;
  name: string;
  reportFormat: ReportFormat | null;
  reportTemplateDto: {
    id: number;
    name: string;
    description: null;
    templateFileDto: null;
  };
  createdAt: number;
  lastModifiedAt: number;
  email: string[];
  companies: ReportCompany[];
  schedule: ReportSchedule;
  reportTemplate?: ReportTemplateContent;
  reportParameters: TemplateParameterWithValue[];
  reportTabs: TReportBodyReportParameterTab[];
  // format: TFormat
};

export type TReport = {
  id: number;
  name: string;
  description?: string;
  format?: ReportFormat | null;
  updatedAt?: string;
  createdAt?: string;
  companies?: string;
  schedule?: string | React.ReactNode;
  // companiesRt?: ReportCompany[];
};

export type Member = {
  id: number;
  name: string;
  email: string;
  createdAt: string;
  lastActivityAt: string;
};
